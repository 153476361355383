import { TFuelTypes } from "../types";

const time_zones = [
     { name: "Alaska Standart Time" },
     { name: "Central Standart Time" },
     { name: "Hawai Standart Time" },
     { name: "Mountain Standart Time" },
     { name: "Pheonix Standart Time" },
     { name: "Pacific Standart Time" },
];

const fuiel_types: TFuelTypes[] = [
     "Diesel",
     "Gasoline",
     "Propane",
     "Liquid Natural Gas",
     "Compressed Natural Gas",
     "Ethanol",
     "Methanol",
     "E-85",
     "M-85",
     "A55",
     "Biodiesel",
     "Other",
];

const issue_stats = [
     "Alaska Standart Time",
     "Central Standart Time ",
     "Hawai Standart Time ",
     "Mountain Standart Time ",
     "Pheonix Standart Time ",
     "Pacific Standart Time ",
];

const state_names = [
     "Alaska",
     "Alabama",
     "Arkansas",
     "American Samoa",
     "Arizona",
     "California",
     "Colorado",
     "Connecticut",
     "District ",
     "of Columbia",
     "Delaware",
     "Florida",
     "Georgia",
     "Guam",
     "Hawaii",
     "Iowa",
     "Idaho",
     "Illinois",
     "Indiana",
     "Kansas",
     "Kentucky",
     "Louisiana",
     "Massachusetts",
     "Maryland",
     "Maine",
     "Michigan",
     "Minnesota",
     "Missouri",
     "Mississippi",
     "Montana",
     "North Carolina",
     "North Dakota",
     "Nebraska",
     "New Hampshire",
     "New Jersey",
     "New Mexico",
     "Nevada",
     "New York",
     "Ohio",
     "Oklahoma",
     "Oregon",
     "Pennsylvania",
     "Puerto Rico",
     "Rhode Island",
     "South Carolina",
     "South Dakota",
     "Tennessee",
     "Texas",
     "Utah",
     "Virginia",
     "Virgin Islands",
     "Vermont",
     "Washington",
     "Wisconsin",
     "West Virginia",
     "Wyoming",
];

export { time_zones, issue_stats, fuiel_types, state_names };
