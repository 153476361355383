import React from "react";
import SiteLoader from "../loaders/SiteLoader";
import { useIsAuth } from "../../hooks/generalHooks";

type Props = {
     children: React.ReactNode;
};

const AuthProvider: React.FC<Props> = ({ children }) => {
     const isIdentified = useIsAuth();

     if (!isIdentified) {
          //! here to disable loader
          return <SiteLoader />;
     }

     return <div className="app">{children}</div>;
};

export default AuthProvider;
