import { POINT_STATUSES } from "../pages/Logs/components/constants";
import {
     ICompany,
     IHistoryLog,
     IService,
     IVehicle,
} from "./../components/types/generalTypes";
import {
     IDriver,
     // IFakeJsonData,
     // IFakeJsonData2,
     ILogData,
     TItemStatus,
     TItemStatusInNumber,
} from "../components/types";

const mapService = (data: IService[]): IService[] => {
     return data.map((el) => {
          return {
               ...el,
               key: el._id,
               // companies: el?.companies ? el?.companies ?  0,
               // drivers: el?.drivers ? el?.drivers ?  0,
               // trucks: el?.trucks ? el?.trucks ?  0,
               dotNumber: +el.dotNumber,
               // status: "inactive"
          };
     });
};

const mapCompanies = (data: ICompany[]) => {
     return data?.map((el: any, i: number) => {
          return {
               ...el,
               key: el._id,
               // service: el.service,
               drivers: el.drivers || 0,
               // trucks: el.trucks || 0,
               // status: el.status === 1 ? "active" : "inactive",
          };
     });
};

const mapDrivers = (data: IDriver[]) => {
     if (!data) return [];
     return data?.map((el, i: number) => {
          return {
               ...el,
               key: el._id,
               no: i + 1,
               name: el.firstName + " " + el.lastName,
               locationName: el?.deviceInfo?.locationName,
               // status: "active", // ! el.status === 1 ? "active" : "inactive",
          };
     });
};

const mapVehicles = (data: IVehicle[]) => {
     return data?.map((el, i: number) => {
          return {
               ...el,
               key: el._id,
               no: i + 1,
               make: `${el.make}/${el.model}`,
               status: "active", // el.status === 1 ? "active" : "inactive",
          };
     });
};
//                            👇👉ILogData[]
const mapDriverLogs = (data: ILogData[]): ILogData[] => {
     const result = data
          // .filter((log) => log.status !== "logout")
          .map((log, i: number) => {
               // const interLogs: ILogData[] = [];// const result: ILogData[] = data.map((log: IFakeJsonData2, i: number) => {
               const getItemStatus = (
                    statusNumber: TItemStatusInNumber
               ): TItemStatus => {
                    switch (statusNumber) {
                         case 1:
                              return "on";
                         case 2:
                              return "dr";
                         case 3:
                              return "sb";
                         case 4:
                              return "off";
                         case 7:
                              return "off_pc";
                         case 6:
                              return "on_ym";
                         case 5:
                              return "intermediate";
                         default:
                              return "off";
                    }
               };
               return {
                    ...log,
                    index: i,
                    number: i + 1,
                    duration: POINT_STATUSES.includes(log.status)
                         ? 0
                         : Math.abs(log.duration),

                    // status: log.status,
                    // location: log.location,
                    // notes: log.notes,
                    isNewLog: false,
                    // location: {
                    //      name: "Tashkent",
                    //      lat: 21,
                    //      long: 22,
                    // },
                    isCropped: false,
               };

               // return {
               //      key: log._id,
               //      id: log._id,
               //      index: i,
               //      number: i + 1,
               //      name: log.driver,
               //      status: log.status,
               //      start: Number(log.created_at),
               //      end: Number(log.created_at + log.hours),
               //      location: log.location,
               //      odometer: log.odometer,
               //      hours: Number(log.hours),
               //      document: log.documents,
               //      notes: log.notes,
               //      vehicle: log.vehicle,
               //      trailers: log.trailers,
               //      isNewLog: false,
               // };
          });
     return result;
};
const mapHistoryLogs = (data: IHistoryLog[]): IHistoryLog[] => {
     return data.filter((historyLog) =>
          historyLog.afterLogs.length === 0 ||
          historyLog.beforeLogs.length === 0
               ? false
               : true
     );
};

export {
     mapService,
     mapCompanies,
     mapDrivers,
     mapVehicles,
     mapDriverLogs,
     mapHistoryLogs,
};
