import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getUserData } from "../api/calls";
import {
     getLocalStorage,
     setUserData,
     setUserType,
     removeLocalStorage,
     historyPush,
} from "../utils";
import { errorMessage } from "../utils/message";
import { RootState } from "../store";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import { ISetState } from "../components/types";

// Identify user authorition
export const useIsAuth = () => {
     const [isAuth, setIsAuth] = useState<boolean>(false);
     useEffect(() => {
          authFunc();
     }, []);
     const authFunc = async () => {
          if (getLocalStorage("token")) {
               try {
                    const res = await getUserData(
                         getLocalStorage("company_id")
                    );
                    const data = res.data;
                    setUserData(data);
                    setUserType(data.type);
               } catch (err: any) {
                    if (err) {
                         removeLocalStorage("token");
                         removeLocalStorage("company_id");
                         errorMessage(err?.message);
                         historyPush("/login");
                    }
               }
          }
          setIsAuth(true);
     };
     return isAuth;
};

// Handle query params
export const useQueryParams = <S = any>(params: string, initialState: S) => {
     const history = useHistory();
     const { search } = useLocation();
     const [queryState, setQueryState] = useState<S>(initialState);
     const [isMounted, setIsMounded] = useState(false);
     useEffect(() => {
          const queryObj: any = qs.parse(search);
          if (isMounted) {
               if (queryState) {
                    queryObj[params] = queryState;
               } else {
                    delete queryObj[params];
               }
               const stringified = qs.stringify(queryObj);
               history.push({ search: stringified });
          } else {
               if (queryObj[params]) {
                    setQueryState(queryObj[params]);
               }
               setIsMounded(true);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [search, queryState]);

     return [queryState, setQueryState] as [S, ISetState<S>];
};

// Select path with type
export const useTypePath = () => {
     const { userType } = useSelector((state: RootState) => state.auth);
     const types = {
          1: "/admin/services", // admin
          2: "/main/dashboard", // company
          3: "/company", // service
     };
     // @ts-ignore
     return userType ? types[userType] : "/";
};

// Listen click event outside
export const useOutsideClick = (initialIsVisible: boolean) => {
     const [isVisible, setIsVisible] = useState<boolean>(initialIsVisible);
     const ref = useRef<HTMLElement>(null);

     const handleClickOutside = (event: any) => {
          if (ref.current && !ref.current.contains(event.target)) {
               setIsVisible(false);
          }
     };

     useEffect(() => {
          document.addEventListener("click", handleClickOutside, true);
          return () => {
               document.removeEventListener("click", handleClickOutside, true);
          };
     }, []);

     return { ref, isVisible, setIsVisible };
};
