import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from './components/constants';
import store from './store';
import 'antd/dist/antd.less';
import "./styles/index.scss"

export const history = createBrowserHistory()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <App />
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
      </QueryClientProvider>
    </Router>
  </Provider>
);
