import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, UserTypes, ThemeProps } from "../../components/types";
import { ILoginData } from "../../pages/Login/login.types";

// interface of reducer
type State = {
     userData: IUser | null;
     userType: UserTypes | null;
     isAuth: boolean;
     isNetworkErr: boolean;
     theme: ThemeProps;
     company: any;
     ok: null;
};

// initial values of reducer
const initialState: State = {
     userData: null,
     userType: null,
     isAuth: false,
     isNetworkErr: false,
     theme: "light",
     company: null,
     ok: null,
};

const AuthSlice = createSlice({
     name: "auth",
     initialState: initialState,
     reducers: {
          setIsAuth: (state: State, action: any) => {
               state.isAuth = action.payload;
          },
          setUserData: (state: State, action: PayloadAction<IUser>) => {
               state.userData = action.payload;
               // state.userType = action.payload?.type;
               state.isAuth = true;
               // state.company = action.pa;
          },
          setIsNetworkErr: (state: State, action: any) => {
               state.isNetworkErr = action.payload;
          },
          setTheme: (state: State, action: any) => {
               state.theme = action.payload;
          },
     },
});

export default AuthSlice;
